<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmtidepoints.title')">
					<v-layout wrap>
						<v-flex xs12 :md6="!isSuperAdmin" :md3="isSuperAdmin">
							<pui-text-field :label="$t('pmtidepoints.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 :md6="!isSuperAdmin" :md4="isSuperAdmin">
							<pui-text-field :label="$t('pmtidepoints.code')" v-model="model.code" required toplabel maxlength="150"></pui-text-field>
						</v-flex>
						<v-flex xs12 md4 v-if="isSuperAdmin">
							<pui-select
								attach="pmorganizationid"
								:label="this.$t('pmtidepoints.organization')"
								:placeholder="this.$t('pmtidepoints.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
						</v-flex>
						<v-flex xs12 :md4="!isSuperAdmin" :md3="isSuperAdmin">
							<pui-text-field
								:label="$t('pmdatasourceinstance.code')"
								v-model="model.code"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<pui-field-set :title="$t('pmtidepoints.location')">
						<v-layout wrap>
							<v-flex xs12 md6>
								<pui-text-field :label="$t('pmtidepoints.longitude')" required v-model="model.longitude" toplabel maxlength="150">
								</pui-text-field>
							</v-flex>
							<v-flex xs12 md6>
								<pui-text-field :label="$t('pmtidepoints.latitude')" required v-model="model.latitude" toplabel maxlength="150">
								</pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmtidepoints.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmtidepoints.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { isSuperAdmin } from '@/api/common';

export default {
	name: 'pmtidepointsform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmtidepoints',
			model: 'pmtidepoints',
			isSuperAdmin: false
		};
	},
	computed: {
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	updated() {
		if (this.model) {
			if (!isSuperAdmin(this.session.profiles[0])) {
				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	methods: {}
};
</script>
